import styled from 'styled-components';

export const SpaceBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexEnd = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const FlexStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
